import React, {
    Suspense, useEffect,
    useState, lazy, FC,
} from 'react';

import { Switch, Route } from 'react-router-dom';

import PrivateRoute from '~/components/PrivateRoute';
import StageIndicator from '~/components/StageIndicator';
import useDidMount from '~/hooks/use-did-mount';
import { useAppDispatch } from '~/hooks/use-redux';
import thunks from '~/redux/thunks';
import { renderRoute } from '~/routes/manager';
import { allRoutes } from '~/routes/public';

import AppLayout from './AppLayout';

const NotFoundPage = lazy(() => import('~/domains/errors/NotFoundPage'));

const hideSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen');
    if (splashScreen) {
        setTimeout(() => {
            splashScreen.classList.add('splash-screen-hide');
            setTimeout(
                () => splashScreen.parentNode.removeChild(splashScreen),
                1000,
            );
        }, 2000);
    }
};

const Layout: FC = () => {
    const [ready, setReady] = useState(false);
    const dispatch = useAppDispatch();

    const loadData = async () => {
        try {
            await dispatch(thunks.user.loadAuthenticationData());
        } catch (ex) {
            console.warn(ex);
        } finally {
            setReady(true);
        }
    };

    useDidMount(() => {
        loadData();
    });

    useEffect(() => {
        if (ready) {
            hideSplashScreen();
        }
    }, [ready]);

    if (!ready) {
        return (
            <div>
                Carregando usuário...
            </div>
        );
    }

    return (
        <Suspense fallback={<div>Carregando...</div>}>
            <Switch>
                {allRoutes.map((route, index) => {
                    return renderRoute(route, index);
                })}
                <PrivateRoute path="/app" component={AppLayout} />
                <Route component={NotFoundPage} />
            </Switch>
            <StageIndicator />
        </Suspense>
    );
};

export default Layout;
