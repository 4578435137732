import {
    createSlice, PayloadAction,
    createAction,
} from '@reduxjs/toolkit';

const logoutUser = createAction('USER/logoutUser');

const userSlice = createSlice({
    name: 'USER',
    initialState: {
        accessToken: null as string,
        refreshToken: null as string,
        account: null as App.LoggedUser,
        skipRefreshToken: false,
        company: null as App.Company,
    },
    reducers: {
        setToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload;
        },
        setRefreshToken: (state, action: PayloadAction<string>) => {
            state.refreshToken = action.payload;
        },
        setAccount: (state, action: PayloadAction<App.LoggedUser>) => {
            state.account = action.payload;
        },
        updateAccount: (state, action: PayloadAction<App.LoggedUser>) => {
            state.account = {
                ...state,
                ...action.payload,
            };
        },
        setSkipRefreshToken: (state, action: PayloadAction<boolean>) => {
            state.skipRefreshToken = action.payload;
        },
        setCompany: (state, action: PayloadAction<App.Company>) => {
            state.company = action.payload;
        },
    },
});

export const actions = {
    ...userSlice.actions,
    logoutUser,
};

export const reducers = userSlice.reducer;
