import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import JwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment-timezone';

import UserPreferences from '~/helpers/user-preferences-helper';
import { actions } from '~/redux/ducks/user';

const TOKEN_KEY = 'TOKEN_KEY';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN_KEY';
const USER_KEY = 'USER_KEY';

export const setAuthenticationUser = createAsyncThunk<void, App.LoggedUser>(
    'USER/setAuthenticationUser',
    async (user, thunkAPI) => {
        await UserPreferences.setItem(USER_KEY, user);
        thunkAPI.dispatch(actions.setAccount(user));
    },
);

type TokenData = {
    accessToken: string;
    refreshToken: string;
    expiresIn: number;
    notBeforePolicy: number;
    refreshExpiresIn: number;
    scope: string;
    sessionState: string;
    tokenType: string;
};

type AuthenticationTokenType = {
    tokenData: TokenData;
    keepLoggedIn: boolean;
};

const setAuthenticationToken = createAsyncThunk<void, AuthenticationTokenType>(
    'USER/setAuthenticationToken',
    async ({ tokenData, keepLoggedIn }, thunkAPI) => {
        const { accessToken, refreshToken } = tokenData;

        await UserPreferences.setItem(TOKEN_KEY, accessToken, { temporary: !keepLoggedIn });
        await UserPreferences.setItem(REFRESH_TOKEN_KEY, refreshToken, { temporary: !keepLoggedIn });
        thunkAPI.dispatch(actions.setToken(accessToken));
        thunkAPI.dispatch(actions.setRefreshToken(refreshToken));
    },
);

type AuthenticationDataType = {
    tokenData: TokenData;
    user: App.LoggedUser;
    skipRefreshToken: boolean;
    keepLoggedIn: boolean;
};

const decodeToken = (token: string): JwtPayloadExtended => {
    try {
        return JwtDecode(token);
    } catch (ex) {
        console.warn(ex);
    }
    return null;
};

const getInitialCompany = async (token: string): Promise<App.Company> => {
    const { data } = await axios.get('/user/me/companies', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return data[0];
};

export const setAuthenticationData = createAsyncThunk<void, AuthenticationDataType>(
    'USER/setAuthenticationData',
    async ({ tokenData, skipRefreshToken, keepLoggedIn }, thunkAPI) => {

        const initialCompany = await getInitialCompany(tokenData.accessToken);

        await thunkAPI.dispatch(setAuthenticationToken({ tokenData, keepLoggedIn }));

        const payload = decodeToken(tokenData.accessToken);
        const { name, email } = payload;

        await thunkAPI.dispatch(actions.setAccount({
            id: 1, // Id fixo, pois não vem no payload
            name,
            email,
        }));

        await thunkAPI.dispatch(actions.setCompany(initialCompany));

        thunkAPI.dispatch(actions.setSkipRefreshToken(skipRefreshToken));
    },
);

export const removeAuthenticationData = createAsyncThunk(
    'USER/removeAuthenticationData',
    async (arg, thunkAPI) => {
        UserPreferences.clear();
        thunkAPI.dispatch(actions.logoutUser());
    },
);

interface JwtPayloadExtended extends JwtPayload {
    name: string;
    email: string;
}

export const loadAuthenticationData = createAsyncThunk(
    'USER/loadAuthenticationData',
    async (arg, thunkAPI) => {
        const token = await UserPreferences.getItem(TOKEN_KEY);
        if (!token) {
            return false;
        }

        const payload = decodeToken(token);
        if (!payload) {
            return false;
        }

        const expirationDate = new Date(payload.exp * 1000);
        if (moment().isAfter(expirationDate)) {
            await thunkAPI.dispatch(removeAuthenticationData());
            return false;
        }

        const { name, email } = payload;

        thunkAPI.dispatch(actions.setToken(token));

        await thunkAPI.dispatch(actions.setAccount({
            id: 1, // Id fixo, pois não vem no payload
            name,
            email,
        }));

        const initialCompany = await getInitialCompany(token);
        await thunkAPI.dispatch(actions.setCompany(initialCompany));

        thunkAPI.dispatch(actions.setSkipRefreshToken(false));

        return true;
    },
);

export const refreshUserAuthentication = createAsyncThunk(
    'USER/refreshUserAuthentication',
    async (arg, thunkAPI) => {
        try {

            const options = await UserPreferences.options(TOKEN_KEY);
            const refreshToken = await UserPreferences.getItem(REFRESH_TOKEN_KEY);

            const response = await axios.post('/public/login/refresh-token', {
                refreshToken,
            });

            return await thunkAPI.dispatch(setAuthenticationData({
                ...response.data,
                keepLoggedIn: !options.temporary,
            }));
        } catch (ex) {
            const { response } = ex;

            const errorCodes = [401, 403];

            if (response && errorCodes.includes(response.status)) {
                return thunkAPI.dispatch(removeAuthenticationData());
            }
            throw ex;
        }
    },
);
