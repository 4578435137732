import React, {
    useState, useCallback,
    useMemo, memo, useImperativeHandle, forwardRef,
} from 'react';

import { Empty, message } from 'antd';
import throttle from 'lodash/throttle';

import { wrapFormField } from '~/helpers/form-helper';
import useAxios from '~/hooks/use-axios';

import Input from '../Input';
import requestCompanies from './request-companies';
import requestContractTerms from './request-contract-terms';
import requestCurrencies from './request-currencies';
import requestGroupRule from './request-group-rule';

const LIMIT = 100;
const INITIAL_PAGE = 0;

const useRequestByType = searchType => {
    return useMemo(() => {
        switch (searchType) {
            case 'company': return requestCompanies;
            case 'contract-term': return requestContractTerms;
            case 'group-rule': return requestGroupRule;
            case 'currencies': return requestCurrencies;

            default: return Promise.resolve;
        }
    }, [searchType]);
};

const requestOptions = async ({
    axios,
    requestSearch,
    searchValue,
    page,
    dataSource,
    params,
}) => {
    try {
        const firstPage = page === INITIAL_PAGE;

        const response = await requestSearch(axios, searchValue, page, LIMIT, params);

        const { result, paginationData } = response;

        return {
            dataSource: firstPage ? result : [...dataSource, ...result],
            canLoadMore: paginationData ? paginationData.pageNumber < (paginationData.totalPages - 1) : null,
            page: paginationData ? paginationData.pageNumber : null,
        };
    } catch (err) {
        console.warn(err);
        if (err.response) {
            message.error('Não foi possível carregar as opções, tente novamente mais tarde.');
        } else {
            message.error('Você parece estar offline, verifique sua conexão com a Internet.');
        }
        throw err;
    }
};

const InputSearch = memo(forwardRef(({
    input, params, searchType,
    onDropdownVisibleChange, useManualFilter,
    filterOptions,
    ...others
}, ref) => {
    const axios = useAxios();
    const requestSearch = useRequestByType(searchType);

    const [fetching, setFetching] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [canLoadMore, setCanLoadMore] = useState(false);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    useImperativeHandle(ref, () => ({
        clearOptions: () => {
            setDataSource([]);
            setCanLoadMore(false);
            setPage(0);
        },
    }), []);

    const filteredDataSource = useMemo(() => {
        if (!useManualFilter) return dataSource;
        return filterOptions(dataSource, searchValue);
    }, [dataSource, filterOptions, searchValue, useManualFilter]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(throttle(
        async value => {
            setFetching(true);
            setDataSource([]);
            setCanLoadMore(false);
            setPage(INITIAL_PAGE);
            setSearchValue(value);

            const result = await requestOptions({
                axios,
                dataSource: [],
                page: INITIAL_PAGE,
                searchValue: value,
                requestSearch,
                params,
            });

            setDataSource(result.dataSource);
            setCanLoadMore(result.canLoadMore);
            setPage(result.page);
            setFetching(false);
        },
        2000,
        {
            trailing: true,
        },
    ), [axios, requestSearch, params]);

    const handleTypeSearch = useCallback(lastSearchValue => {
        if (useManualFilter) {
            setSearchValue(lastSearchValue);
            return;
        }
        handleSearch(lastSearchValue);
    }, [handleSearch, useManualFilter]);

    const handleLoadMore = useCallback(async () => {
        setFetching(true);

        const nextPage = page + 1;

        const result = await requestOptions({
            axios,
            dataSource,
            page: nextPage,
            searchValue,
            requestSearch,
            params,
        });

        setDataSource(result.dataSource);
        setCanLoadMore(result.canLoadMore);
        setPage(result.page);
        setFetching(false);
    }, [page, axios, dataSource, searchValue, requestSearch, params]);

    const handleDropdownVisibleChange = useCallback(visible => {
        if (onDropdownVisibleChange) onDropdownVisibleChange(visible);

        if (visible && !dataSource.length) {
            handleSearch('');
        }
    }, [onDropdownVisibleChange, dataSource, handleSearch]);

    const inputProps = useMemo(() => ({
        ...input,
        type: 'select',
    }), [input]);

    return (
        <Input
            {...others}
            onSearch={handleTypeSearch}
            options={filteredDataSource}
            input={inputProps}
            loading={fetching}
            showSearch
            notFoundContent={<Empty description={false} />}
            onLoadMoreClick={canLoadMore ? handleLoadMore : null}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            filterOption={false}
        />
    );
}));

InputSearch.defaultProps = {
    filterOptions: (dataSource, searchValue) => {
        const parsedInputStr = searchValue.accentsFolding().toLowerCase();

        return dataSource.filter(value => {
            const parsedValue = value.label.accentsFolding().toLowerCase();
            return parsedValue.includes(parsedInputStr);
        });
    },
    useManualFilter: true,
};

InputSearch.Field = wrapFormField(InputSearch);

export default InputSearch;
