import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const collapsedSlice = createSlice({
    name: 'SIDEBAR',
    initialState: {
        collapsed: false,
    },
    reducers: {
        toggleSidebar: (state, action: PayloadAction<boolean>) => {
            state.collapsed = typeof action.payload === 'boolean' ? action.payload : !state.collapsed;
        },
    },
});

export const actions = collapsedSlice.actions;

export const reducers = collapsedSlice.reducer;
