import React, { useCallback, useMemo, useState } from 'react';

import { Layout, Menu, Drawer } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter, matchPath } from 'react-router-dom';
import urlJoin from 'url-join';

import Icon from '~/components/Icon';
import { connectScreenSize } from '~/helpers/screen-size-helper';
import useDidMount from '~/hooks/use-did-mount';
import useDidMountAndUpdate from '~/hooks/use-did-mount-and-update';
import { actions } from '~/redux/ducks';
import routes, { allRoutes } from '~/routes/app';
import { findParentGroups, hasRoutePermission } from '~/routes/manager';
import colors from '~/values/colors';
import strings from '~/values/strings';

import styles from './AppSideMenu.module.scss';

const { Sider } = Layout;
const { Item: MenuItem, SubMenu } = Menu;

const getItemIcon = icon => {
    if (!icon) return null;
    return (
        <i className={classNames(['anticon', styles.menuItemIcon])}>
            <Icon family={icon.family} name={icon.name} size={20} />
        </i>
    );
};

const SIDEBAR_WIDTH = 230;
const DRAWER_BODY_STYLE = { padding: 0, heigth: '100%' };

const AppSideMenu = ({
    toggleSidebar, isDesktop, sidebar,
    theme, location,
}) => {

    const [openedKeys, setOpenedKeys] = useState([]);

    useDidMount(() => {
        toggleSidebar(!isDesktop);
    });

    useDidMountAndUpdate(() => {
        if (sidebar.collapsed) {
            setOpenedKeys([]);
        }
    }, [sidebar.collapsed]);

    const handleCollapse = useCallback((collapsed, type) => {
        toggleSidebar(collapsed);
    }, [toggleSidebar]);

    const handleCloseDrawer = useCallback(() => {
        handleCollapse(true);
    }, [handleCollapse]);

    const handleLinkClick = useCallback(() => {
        if (!isDesktop) {
            toggleSidebar(true);
        }
    }, [isDesktop, toggleSidebar]);

    const menuState = useMemo(() => {
        const { pathname } = location;

        const selectedRoute = allRoutes
            .find(route => {
                return matchPath(pathname, {
                    path: urlJoin('/app', route.path),
                    exact: route.exact,
                });
            });

        if (!selectedRoute) {
            return { selectedRoute: {}, openedGroups: [] };
        }

        const openedGroups = sidebar.collapsed
            ? []
            : findParentGroups(routes, selectedRoute).map(r => r.group);

        return {
            selectedRoute,
            openedGroups: [
                ...openedKeys,
                ...openedGroups.filter(item => (
                    openedKeys.indexOf(item) === -1
                )),
            ],
        };
    }, [location, openedKeys, sidebar.collapsed]);

    const drawerBodyStyle = useMemo(() => ({
        ...DRAWER_BODY_STYLE,
        backgroundColor: theme === 'dark' ? colors.primaryDark : colors.white,
    }), [theme]);

    const renderLogo = () => {
        return (
            <div className={styles.logo}>
                <Link to="/app" title={strings.appName}>
                    <img
                        src={sidebar.collapsed
                            ? require('~/assets/logoCollapsed.svg').default
                            : require('~/assets/logo.svg').default}
                        alt={strings.appName}
                        className={classNames([
                            styles.imgIcone,
                            {
                                [styles.imgCollapsed]: sidebar.collapsed,
                            },
                        ])}
                    />
                </Link>
            </div>
        );
    };

    const renderMenuGroup = route => {
        if (!hasRoutePermission(route)) return null;

        return (
            <SubMenu
                key={route.group}
                title={(
                    <span>
                        {getItemIcon(route.icon)}
                        <span className="nav-text">
                            {route.group}
                        </span>
                    </span>
                )}
                className={styles.subMenu}
            >
                {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                {route.routes.map(renderMenuItem)}
            </SubMenu>
        );
    };

    const renderMenuItem = route => {
        if (route.group) return renderMenuGroup(route);

        if (!route.text) return null;

        if (!hasRoutePermission(route)) return null;

        return (
            <MenuItem className={styles.menuItem} key={route.menuKey} title={route.longText}>
                <Link
                    // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                    to={{
                        pathname: urlJoin('/app', route.path),
                        search: route.query,
                    }}
                    onClick={handleLinkClick}
                >
                    {getItemIcon(route.icon)}
                    <span>
                        {route.text}
                    </span>
                </Link>
            </MenuItem>
        );
    };

    const renderContent = () => {
        const { selectedRoute, openedGroups } = menuState;
        return (
            <div className={styles.scrollContainer}>
                {renderLogo()}

                <Menu
                    theme={theme}
                    mode="inline"
                    // eslint-disable-next-line react-perf/jsx-no-new-array-as-prop
                    selectedKeys={[selectedRoute.menuKey]}
                    openKeys={openedGroups}
                    onOpenChange={setOpenedKeys}
                    className={styles.menu}
                >
                    {routes.map(renderMenuItem)}
                </Menu>
            </div>
        );
    };

    if (!isDesktop) {
        return (
            <Drawer
                placement="left"
                onClose={handleCloseDrawer}
                visible={!sidebar.collapsed}
                width={SIDEBAR_WIDTH}
                bodyStyle={drawerBodyStyle}
                className={styles.drawer}
            >
                {renderContent()}
            </Drawer>
        );
    }

    return (
        <Sider
            theme={theme}
            trigger={null}
            collapsible
            collapsed={sidebar.collapsed}
            onCollapse={handleCollapse}
            width={SIDEBAR_WIDTH}
            className={styles.sider}
        >
            {renderContent()}
        </Sider>
    );
};

AppSideMenu.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    isDesktop: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    theme: PropTypes.string,
};

AppSideMenu.defaultProps = {
    theme: 'light',
};

const mapStateToProps = ({ sidebar }) => ({
    sidebar,
});

const mapDispatchToProps = {
    toggleSidebar: actions.sidebar.toggleSidebar,
};

export default connectScreenSize(
    withRouter(
        connect(mapStateToProps, mapDispatchToProps)(AppSideMenu),
    ),
);
