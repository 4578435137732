import React, { useMemo, memo } from 'react';

import { Avatar, Popover } from 'antd';
import PropTypes from 'prop-types';

import { resolveImageUrl } from '~/helpers/storage-helper';

const COLORS = [
    '#DA532C', // orange
    '#00ABA9', // teal
    '#2D89EF', // blue
    '#00A300', // green
    '#FF0097', // pink
    '#7E3878', // magenta
    '#603CBA', // purple
    '#5E4B8B', // violet
    '#99B433', // lightGreen
    '#6BA5E7', // lightBlue
    '#E773BD', // lightPink
    '#1E7145', // darkGreen
    '#2B5797', // darkBlue
    '#B91D47', // darkRed
];

const getInitialsColorFromName = displayName => {
    let color = COLORS[0];
    if (!displayName) {
        return color;
    }
    let hashCode = 0;
    for (let iLen = displayName.length - 1; iLen >= 0; iLen--) { // eslint-disable-line
        const ch = displayName.charCodeAt(iLen);
        const shift = iLen % 8;

        hashCode ^= (ch << shift) + (ch >> (8 - shift)); // eslint-disable-line
    }
    color = COLORS[hashCode % COLORS.length];
    return color;
};

const getFirstAndLastName = fullName => {
    const parts = (fullName || '').trim().split(' ');
    if (parts.length < 2) return '';
    return `${parts[0]} ${parts[parts.length - 1]}`;
};

const initialsFromName = fullName => {
    const parts = fullName.trim().split(' ');
    if (parts.length < 2) return '';
    return `${parts[0][0]}${parts[parts.length - 1][0]}`.toUpperCase();
};

const UserAvatar = memo(({
    photo, name, email,
    hidePopover, size,
    ...props
}) => {
    const photoUrl = useMemo(() => {
        return photo ? resolveImageUrl(photo, size * 2) : undefined;
    }, [photo, size]);

    const firstAndLastName = useMemo(() => getFirstAndLastName(name), [name]);

    const style = useMemo(() => {
        return !photo
            ? {
                backgroundColor: getInitialsColorFromName(firstAndLastName),
            }
            : undefined;
    }, [photo, firstAndLastName]);

    const children = useMemo(() => {
        return !photo ? initialsFromName(firstAndLastName) : undefined;
    }, [photo, firstAndLastName]);

    const content = (
        <Avatar
            shape="circle"
            {...props}
            size={size}
            src={photoUrl}
            style={style}
        >
            {children}
        </Avatar>
    );

    if (hidePopover) return content;

    return (
        <Popover
            content={(
                <>
                    <strong>{name}</strong>
                    {' <'}
                    {email}
                    {'>'}
                </>
            )}
        >
            {content}
        </Popover>
    );
});

UserAvatar.propTypes = {
    size: PropTypes.number,
    hidePopover: PropTypes.bool,
};

UserAvatar.defaultProps = {
    size: 32,
    hidePopover: false,
};

export default UserAvatar;
