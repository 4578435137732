const request = async (axios, searchValue, page, limit, params = {}) => {
    const { data } = await axios.get('/user/me/companies');

    return {
        result: data.map(company => ({
            label: company.name,
            key: company.id,
            item: company,
        })),
    };
};

export default request;
