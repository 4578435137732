import React, { useState } from 'react';

import { Layout } from 'antd';
import { connect, useSelector } from 'react-redux';

import useDidMount from '~/hooks/use-did-mount';
import thunks from '~/redux/thunks';

import AppHeaderBar from './AppHeaderBar';
import styles from './AppLayout.module.scss';
import AppRoutes from './AppRoutes';
import AppSideMenu from './AppSideMenu';

const { Content } = Layout;

const AppLayout = ({ refreshUserAuthentication, history }) => {
    const [ready, setReady] = useState(false);

    const { skipRefreshToken } = useSelector(state => state.user);

    const navigateToLanding = () => {
        history.push('/');
    };

    const navigateToLogin = () => {
        history.push('/login');
    };

    const refreshToken = async () => {
        try {
            await refreshUserAuthentication();
            setReady(true);
        } catch (ex) {
            console.warn(ex);
            const { response } = ex;
            if (!response) {
                navigateToLanding();
            } else {
                navigateToLogin();
            }
        }
    };

    useDidMount(() => {
        if (!skipRefreshToken) {
            refreshToken();
        } else {
            setReady(true);
        }
    });

    if (!ready) {
        return 'Atualização autorização de acesso...';
    }

    return (
        <div className={styles.container}>
            <Layout className={styles.layout}>

                <AppSideMenu />

                <Content className={styles.content}>

                    <AppHeaderBar />
                    <AppRoutes />

                </Content>
            </Layout>
        </div>
    );
};

const mapDispatchToProps = {
    refreshUserAuthentication: thunks.user.refreshUserAuthentication,
};

export default connect(null, mapDispatchToProps)(AppLayout);
