import { configureStore } from '@reduxjs/toolkit';

import { actions, reducers } from './ducks';

export type AppReducers = typeof reducers;

const mainReducer: AppReducers = (state, action) => {
    if (action.type === actions.user.logoutUser().type) {
        return reducers({} as any, action);
    }
    return reducers(state, action);
};

const store = configureStore({
    reducer: mainReducer,
    devTools: window.__DEV__,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

if (window.__DEV__) {
    module.hot?.accept('./ducks', () => {
        store.replaceReducer(mainReducer);
    });
    module.hot?.accept('./thunks', () => {
        store.replaceReducer(mainReducer);
    });
}

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
