const request = async (axios, searchValue, page, limit, params = {}) => {

    const { data } = await axios.get('/plan/group-rule', {
        params: { 
            productId: params.productId,
        },
    });

    return {
        result: data.map(groupRule => ({
            label: groupRule.name,
            key: groupRule.id,
            item: groupRule,
        })),
    };
};

export default request;
