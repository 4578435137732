import React, { memo, useCallback, useMemo } from 'react';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import {
    Layout,
    Button, Popover,
    Divider,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import InputSearch from '~/components/InputSearch';
import ScreenSizeDebugger from '~/components/ScreenSizeDebugger';
import UserAvatar from '~/components/UserAvatar';
import { connectScreenSize } from '~/helpers/screen-size-helper';
import { actions } from '~/redux/ducks';
import thunks from '~/redux/thunks';

import styles from './AppHeaderBar.module.scss';

const { Header } = Layout;

const AppHeaderBar = memo(({
    toggleSidebar, sidebar, removeAuthenticationData,
    history, user, setCompany,
}) => {
    const { account, company } = user;

    const handleLogout = useCallback(async () => {
        await removeAuthenticationData();
        history.push('/');
    }, [history, removeAuthenticationData]);

    const renderUserOptions = () => {
        return (
            <div>
                <div className={styles.profile}>
                    <b>{account.name}</b>
                </div>
                <Divider className={styles.optionDivider} />
                <div
                    onClick={handleLogout}
                    role="button"
                    tabIndex="0"
                    className={styles.menuItemContainer}
                >
                    Sair
                </div>
            </div>
        );
    };

    const selectedCompanyValue = useMemo(() => {
        if (company) {
            return {
                key: company.id,
                label: company.name,
            };
        }

        return null;
    }, [company]);

    const onChangeCompany = useCallback(value => {
        if (value.key !== company.id) {
            setCompany(value.item);
            history.push('/app');
        }
    }, [company, history, setCompany]);

    const renderSelectComany = () => {
        return (
            <InputSearch
                key="company"
                name="company"
                hasFormItem={false}
                searchType="company"
                onChange={onChangeCompany}
                value={selectedCompanyValue}
                className={styles.inputSearchCompany}
                placeholder="Selecione a organização"
            />
        );
    };

    const renderAvatar = () => {
        const { name, email, foto_perfil: profilePicture } = account;

        return (
            <Popover
                placement="bottomRight"
                content={renderUserOptions()}
                trigger="focus"
            >
                <a onClick={null} role="button" tabIndex="-1">
                    <UserAvatar
                        photo={profilePicture && profilePicture.caminho}
                        name={name}
                        email={email}
                        className={styles.avatar}
                        hidePopover
                    />
                </a>
            </Popover>
        );
    };

    return (
        <Header className={styles.header}>

            <div className={styles.headerContainer}>
                <div className={styles.menuButton}>
                    <Button onClick={toggleSidebar}>
                        {sidebar.collapsed
                            ? (
                                <MenuUnfoldOutlined />
                            ) : (
                                <MenuFoldOutlined />
                            )}
                    </Button>
                </div>

                <ScreenSizeDebugger />

                <div className={styles.contentOnRight}>
                    {renderSelectComany()}
                    <div className={styles.avatarContainer}>
                        {renderAvatar()}
                    </div>
                </div>
            </div>

        </Header>
    );

});

AppHeaderBar.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    sidebar, user,
}) => ({
    sidebar,
    user,
});

const mapDispatchToProps = ({
    toggleSidebar: actions.sidebar.toggleSidebar,
    removeAuthenticationData: thunks.user.removeAuthenticationData,
    setCompany: actions.user.setCompany,
});

export default connect(mapStateToProps, mapDispatchToProps)(connectScreenSize(withRouter(AppHeaderBar)));
