import moment from 'moment-timezone';

import store from '~/redux/store';
import { removeAuthenticationData } from '~/redux/thunks/user';

export function tokenInterceptor(requestConfig) {
    const { user } = store.getState();
    const { accessToken } = user;

    if (accessToken && !requestConfig.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        requestConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    // eslint-disable-next-line no-param-reassign
    requestConfig.headers.timezone = moment.tz.guess();

    return requestConfig;
}

export async function responseErrorInterceptor(error) {
    const { response } = error;

    const errorCodes = [401];

    if (response && errorCodes.includes(response.status)) {
        await store.dispatch(removeAuthenticationData());
    }

    return Promise.reject(error);
} 
