import { lazy } from 'react';

import { getRoutesArray } from './manager';

const HomePage = lazy(() => import('~/domains/home/HomePage'));
const ProductsListPage = lazy(() => import('~/domains/products'));
const ProductViewPage = lazy(() => import('~/domains/products/ProductViewPage'));
const ProductsCreateUpdatePage = lazy(() => import('~/domains/products/ProductsCreateUpdatePage'));
const SubscriptionsListPage = lazy(() => import('~/domains/subscriptions'));
const SubscriptionViewPage = lazy(() => import('~/domains/subscriptions/SubscriptionViewPage'));

const ROUTES = [
    {
        path: '/',
        menuKey: '/',
        component: HomePage,
        text: 'Home',
        icon: {
            name: 'dashboard',
        },
        exact: true,
    },
    {
        path: '/produtos',
        menuKey: '/produtos',
        component: ProductsListPage,
        text: 'Produtos',
        icon: {
            name: 'shopping-basket',
        },
        exact: true,
    },
    {
        path: '/produtos/novo',
        menuKey: '/produtos',
        component: ProductsCreateUpdatePage,
        exact: true,
    },
    {
        path: '/produtos/:productId',
        menuKey: '/produtos',
        component: ProductsCreateUpdatePage,
        exact: true,
    },
    {
        path: '/produtos/:productId/visualizar',
        menuKey: '/produtos',
        component: ProductViewPage,
        exact: true,
    },
    {
        path: '/assinaturas',
        menuKey: '/assinaturas',
        component: SubscriptionsListPage,
        text: 'Assinaturas',
        icon: {
            name: 'receipt',
        },
        exact: true,
    },
    {
        path: '/assinatura/:subscriptionId/visualizar',
        menuKey: '/assinaturas',
        component: SubscriptionViewPage,
        exact: true,
    },
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
