export const createCurrencyOption = currency => ({
    label: `${currency.isoCode} - ${currency.name} (${currency.symbol})`,
    key: currency.id,
    item: currency,
});

const request = async (axios, searchValue, page, limit, params = {}) => {
    const { data } = await axios.get('currency');

    return { result: data.map(createCurrencyOption) };
};

export default request;
