import React, { FC, memo, useMemo } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';

const RedirectToLogin = ({ location }) => {
    const to = useMemo(() => ({
        pathname: '/',
        state: { from: location },
    }), [location]);
    return (
        <Redirect
            to={to}
        />
    );
};

RedirectToLogin.propTypes = {
    location: PropTypes.object.isRequired,
};

export interface PrivateRouteProps extends RouteProps {
    isLoggedIn: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = memo(({
    render,
    component,
    isLoggedIn,
    ...others
}) => {
    return (
        <Route
            {...others}
            render={isLoggedIn ? render : RedirectToLogin}
            component={isLoggedIn ? component : memo(RedirectToLogin)}
        />
    );
});

const mapStateToProps = ({ user }) => ({
    isLoggedIn: Boolean(user.accessToken),
});

export default connect(mapStateToProps)(PrivateRoute);
