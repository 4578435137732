import store from '~/redux/store';

const request = async (axios, searchValue, page, limit, params = {}) => {
    const { user } = store.getState();

    const response = await axios.get('/contract-term', {
        params: {
            companyId: user.company.id,
            size: limit,
            page,
        },
    });

    const { data } = response;

    const paginationData = {
        pageNumber: data.pageable.pageNumber,
        totalPages: data.totalPages,
    };

    const { content } = data;

    return {
        paginationData,
        result: content.map(contractTerm => ({
            label: contractTerm.description,
            key: contractTerm.id,
            item: contractTerm,
        })),
    };
};

export default request;
