import { lazy } from 'react';

import { getRoutesArray } from './manager';

const LoginPage = lazy(() => import('~/domains/signin/LoginPage'));

const ROUTES = [
    {
        path: '/',
        component: LoginPage,
        exact: true,
    },
];

export default ROUTES;

export const allRoutes = getRoutesArray({
    group: 'root',
    routes: ROUTES,
});
